/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import ReactFullpage from '@fullpage/react-fullpage';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { useEventListener, useMobile } from '../hooks/index';

import Layout from '../components/layout';
import SEO from '../components/seo';
import localize from '../components/localize';
import RichText from '../components/richtext';
import Footer from '../components/footer';

const ContactPage = ({ data }) => {
  const contact = data.contact.edges.map(edge => edge.node);
  const {
    _rawViennaSubline,
    _rawVienna,
    tokyoSubline,
    _rawTokyo,
    mail,
    phone,
    viennaImage,
    tokyoImage,
  } = contact[0];

  const [activeSection, setActiveSection] = useState();
  const afterLoad = (origin, destination) => {
    setActiveSection(destination.index);
  };

  const mailSplit = mail.split('@');
  const mobileMail = [`${mailSplit[0]}@`, mailSplit[1]];
  const mobile = useMobile();

  const [showViennaImg, setShowViennaImg] = useState(false);
  const [showTokyoImg, setShowTokyoImg] = useState(false);
  const [posVienna, setPosVienna] = useState({});
  const [posTokyo, setPosTokyo] = useState({});

  const handleCursor = e => {
    setPosVienna({ x: e.pageX, y: e.pageY });
    setPosTokyo({ x: e.pageX, y: e.pageY });
  };
  useEventListener('mousemove', handleCursor);

  return (
    <>
      <Layout
        current="CONTACT"
        dePath="/contact"
        enPath="/en/contact"
        hideHeader={activeSection !== 0}
        hideNav={activeSection === 1}
        disableUp={activeSection === 0}
        disableDown={activeSection === 0}
        onClickUp={() => window.fullpage_api.moveSectionUp()}
        onClickDown={() => window.fullpage_api.moveSectionDown()}
      >
        <SEO siteTitle="CONTACT" />
        <ReactFullpage
          licenseKey={process.env.GATSBY_FULLPAGE_LICENSE}
          responsiveWidth={1020}
          scrollOverflow
          autoScrolling
          scrollingSpeed={600}
          easingcss3="cubic-bezier(0.700, 0.000, 1.000, 1.000)"
          afterLoad={afterLoad}
          render={() => (
            <div id="fullpage-wrapper">
              <StyledSectionOne className="section light">
                <div className="inner">
                  {showViennaImg && (
                    <div
                      className="img-wrapper"
                      style={{ top: posVienna.y, left: posVienna.x }}
                    >
                      <Img
                        className="img"
                        fluid={viennaImage.image.asset.fluid}
                        alt={viennaImage.alt}
                      />
                    </div>
                  )}
                  {showTokyoImg && (
                    <div
                      className="img-wrapper"
                      style={{ top: posTokyo.y, left: posTokyo.x }}
                    >
                      <Img
                        className="img"
                        fluid={tokyoImage.image.asset.fluid}
                        alt={tokyoImage.alt}
                      />
                    </div>
                  )}
                  <div className="headline">
                    <div
                      className="left right-aligned"
                      onMouseOver={() => setShowViennaImg(true)}
                      onMouseLeave={() => setShowViennaImg(false)}
                    >
                      <div className="subline">
                        <RichText blocks={_rawViennaSubline} />
                      </div>
                      <div className="big">
                        <RichText blocks={_rawVienna} />
                      </div>
                    </div>
                    <div
                      className="right"
                      onMouseOver={() => setShowTokyoImg(true)}
                      onMouseLeave={() => setShowTokyoImg(false)}
                    >
                      <div className="subline japan">
                        <p>{tokyoSubline}</p>
                      </div>
                      <div className="big">
                        <RichText blocks={_rawTokyo} />
                      </div>
                    </div>
                  </div>
                  <div className="big centered">
                    <a
                      href={`mailto:${mail.toLowerCase()}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {mobile ? (
                        mobileMail.map(item => <p>{item}</p>)
                      ) : (
                        <p>{mail}</p>
                      )}
                    </a>
                    <a href={`tel:${phone}`}>
                      <p>{phone}</p>
                    </a>
                  </div>
                </div>
              </StyledSectionOne>
              <Footer />
            </div>
          )}
        />
      </Layout>
    </>
  );
};

const StyledSectionOne = styled.section`
  .inner {
    padding: var(--spacing-L) var(--spacing-XS) var(--spacing-S)
      var(--spacing-XS);
    position: relative;

    .img-wrapper {
      position: absolute;
      pointer-events: none;
      .img {
        width: var(--spacing-XXL);
        pointer-events: none;
      }
    }

    .headline {
      display: flex;
      padding-bottom: var(--spacing-S);
    }

    .subline {
      height: var(--spacing-M);
    }

    .japan {
      font-family: var(--font-3);
    }

    .left,
    .right {
      width: 50%;
      padding: 0 var(--spacing-XXS);
    }

    @media (max-width: 1020px) {
      padding: var(--spacing-S) var(--spacing-XXS);

      .headline {
        flex-direction: column;
      }

      .subline {
        height: auto;
      }

      .left,
      .right {
        text-align: center;
        width: 100%;
        padding: 0;
      }

      .left {
        padding-bottom: var(--spacing-S);
      }
    }
  }
`;

export const query = graphql`
  query Contact {
    contact: allSanityContact {
      edges {
        node {
          _rawViennaSubline
          _rawVienna
          viennaImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          tokyoSubline
          _rawTokyo
          tokyoImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          mail
          phone
        }
      }
    }
  }
`;

ContactPage.propTypes = {
  data: PropTypes.object,
};

export default localize(ContactPage);
